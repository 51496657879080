import * as React from 'react';
import { MapContainer, Polyline, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { isUndefined, isEmpty } from 'lodash';
import L from 'leaflet';
import Markers from './Markers';
import NewRouting from './NewRouting';
import DriverMarker from './DriverMarker';

const OrderMap = (): JSX.Element => {
  const { isMobileView } = useSelector((state: CR.RootState) => state.settings);

  let style;
  if (isMobileView === 'false') {
    style = {
      height: '100vh',
    };
  }

  const { order: orderState } = useSelector((state: CR.RootState) => state.order);
  const order = orderState as CR.OrderCRUD;

  const orderUUID = order.uuid;

  // Start markers creation
  const waypoints = {} as CR.ParseWaypoints;

  waypoints.pickup = order.route_points[0];
  const mapBounds = new L.LatLngBounds(
    [waypoints.pickup.latitude, waypoints.pickup.longitude],
    [waypoints.pickup.latitude, waypoints.pickup.longitude],
  );

  waypoints.destination = undefined;
  waypoints.stops = undefined;
  waypoints.driver = undefined;

  if (order.driver_location) {
    mapBounds.extend([order.driver_location.latitude, order.driver_location.longitude]);
  }

  if (order.route_points.length > 1) {
    waypoints.destination = order.route_points[order.route_points.length - 1];
    mapBounds.extend([waypoints.destination.latitude, waypoints.destination.longitude]);

    waypoints.stops = order.route_points.slice(1, -1);

    if (isEmpty(waypoints.stops)) {
      waypoints.stops = undefined;
    }

    if (!isUndefined(waypoints.stops)) {
      waypoints.stops.forEach((el) => {
        mapBounds.extend([el.latitude, el.longitude]);
      });
    }
  }

  // Set driver positions by tracking
  if (!isUndefined(order.tracking) && !isEmpty(order.tracking)) {
    waypoints.driver = {} as NonNullable<CR.ParseWaypoints['driver']>;
    waypoints.driver.currentPos = order.tracking[order.tracking.length - 1];
    waypoints.tracking = order.tracking;

    if (order.tracking.length > 1) {
      waypoints.driver.prevPos = order.tracking[order.tracking.length - 2];
    } else {
      waypoints.driver.prevPos = order.route_points[0];
    }

    mapBounds.extend([waypoints.driver.prevPos.latitude, waypoints.driver.prevPos.longitude]);
    mapBounds.extend([waypoints.driver.currentPos.latitude, waypoints.driver.currentPos.longitude]);
  }

  // End markers creation

  // Start waypoints for graphhopper
  const generateRoutings = () => {
    const wpRoutes = [] as L.LatLng[];

    // Case 1: no driver => no routing split
    if (isUndefined(order.tracking) || isEmpty(order.tracking)) {
      wpRoutes.push(L.latLng(waypoints.pickup.latitude, waypoints.pickup.longitude));

      if (!isUndefined(waypoints.stops)) {
        waypoints.stops.forEach((el) => {
          wpRoutes.push(L.latLng(el.latitude, el.longitude));
        });
      }

      if (!isUndefined(waypoints.destination)) {
        wpRoutes.push(L.latLng(waypoints.destination.latitude, waypoints.destination.longitude));
      }

      return (
        <NewRouting waypoints={wpRoutes} uuid={orderUUID} />
      );
    }

    // Order completed
    if (order.status.id === 80) {
      order.tracking.forEach((el) => {
        wpRoutes.push(L.latLng(el.latitude, el.longitude));
      });
      return (
        <Polyline color="green" positions={wpRoutes} />
      );
    }

    // Case 2: with driver => split route
    const beforeDriver = [] as L.LatLng[];
    const afterDriver = [] as L.LatLng[];

    order.tracking.forEach((el) => {
      beforeDriver.push(L.latLng(el.latitude, el.longitude));
    });

    let stopsAfterDriver = [] as CR.RoutePoint[];

    if (waypoints.stops) {
      stopsAfterDriver = waypoints.stops.filter((el) => el.ended_at === null);
    }

    if (!isUndefined(waypoints.driver)) {
      afterDriver.push(L.latLng(waypoints.driver.currentPos.latitude, waypoints.driver.currentPos.longitude));
    }

    stopsAfterDriver.forEach((el) => {
      afterDriver.push(L.latLng(el.latitude, el.longitude));
    });

    if (!isUndefined(waypoints.destination)) {
      afterDriver.push(L.latLng(waypoints.destination.latitude, waypoints.destination.longitude));
    }

    return (
      <>
        <Polyline color="green" positions={beforeDriver} />
        <NewRouting waypoints={afterDriver} uuid={orderUUID} />
      </>
    );
  };

  // End waypoints for graphhopper
  return (
    <MapContainer
      bounds={mapBounds}
      boundsOptions={{ padding: [20, 20] }}
      zoomControl={false}
      scrollWheelZoom
      className="mapContainer"
      style={style}
    >
      <TileLayer
        attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
        id="meridiantaxi/clt8ij0ia00ch01qnbx0898pl"
        tileSize={512}
        zoomOffset={-1}
        accessToken={process.env.REACT_APP_MAPBOX}
      />

      <Markers waypoints={waypoints} />

      <DriverMarker />

      {generateRoutings()}
    </MapContainer>
  );
};

export default OrderMap;
