import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import App from './App';
import reportWebVitals from './reportWebVitals';
import en from './locale/en.json';
import ro from './locale/ro.json';
import availableLanguages from './locale/available';

interface TranslationType {
  [index: string]: Record<string, string>,
}

const translations: TranslationType = {
  en,
  ro,
};

const parseLocale = () => {
  const navLocale = navigator.language.slice(0, 2);

  if (availableLanguages.includes(navLocale)) {
    return navLocale;
  }

  return 'en';
};

const navLang = parseLocale();

ReactDOM.render(
  <IntlProvider
    locale={navLang}
    key={navLang}
    defaultLocale="en"
    messages={translations[navLang]}
  >
    <App />
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
