import * as React from 'react';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import BottomSheetContent from './Content';

interface BottomSheetProps {
  defaultOpen?: boolean,
  open?: boolean,
  fullScreen?: boolean,
  marginTop?: number,
  overflowHeight?: number,
  overlay?: boolean,
  scrollTopAtClose?: boolean,
  shadowTip?: boolean,
  swipeableViewsProps?: Record<string, unknown>,
  topShadow?: boolean,
}

type SwipeableCSS = {
  root: React.CSSProperties
  swiper: {
    root: React.CSSProperties,
    container: React.CSSProperties,
    slide: React.CSSProperties
    bottomSlide: React.CSSProperties
    body: React.CSSProperties
  },
  overlay: React.CSSProperties,
  shadowTip: React.CSSProperties
};

type HightUpdaterProps = {
  height: number,
  onHeightChange: (height: number) => void
};

// This seems useless.
// Does not properly listen events
// Does not really update height
const HightUpdater = (props: HightUpdaterProps) => {
  const { height, onHeightChange } = props;

  const onWindowResize = () => {
    const heightTwo = window.innerHeight;

    if (heightTwo !== height) {
      onHeightChange(heightTwo);
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  return null;
};

const BottomSheet = (
  {
    defaultOpen = false,
    fullScreen = false,
    marginTop = 0,
    overflowHeight = 160,
    overlay = true,
    // scrollTopAtClose = true,
    shadowTip = true,
    // swipeableViewsProps = {},
    topShadow = true,
    ...restProps
  }: BottomSheetProps,
): JSX.Element => {
  const {
    open,
  } = restProps;

  const { isMobileView } = useSelector((state: CR.RootState) => state.settings);

  const [height, setHeight] = React.useState(window.innerHeight);
  const [stateOpen, setStateOpen] = React.useState(defaultOpen);

  const bodyRef = React.createRef<HTMLDivElement>();

  const onChangeIndex = (index: number) => {
    const shouldOpen = index === 1;
    if (open === undefined) {
      setStateOpen(shouldOpen);
    }
  };

  const hiddenWhenClosed = overflowHeight === 0;
  const isControlled = open !== undefined;
  const isOpen = isControlled ? open : stateOpen;
  const hideShadows = hiddenWhenClosed && !isOpen;
  const index = isOpen ? 1 : 0;
  const maxHeight = marginTop ? (height - marginTop) : height;

  const styles = {
    root: {
      height: overflowHeight,
      width: isMobileView === 'false' ? '375px' : '',
      position: 'fixed',
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 2000,
      backgroundColor: '#3F4146',
      // ...this.props.style,
    },
    swiper: {
      root: {
        overflowY: 'initial',
        boxSizing: 'border-box',
        // ...swipeableViewsProps.style,
      },
      container: {
        boxSizing: 'border-box',
        ...topShadow && !hideShadows && {
          boxShadow: 'rgba(0, 0, 0, 0.156863) 0px -6px 5px',
        },
        borderRadius: '16px 16px 0 0',
        // ...swipeableViewsProps.containerStyle
      },
      slide: {
        boxSizing: 'border-box',
        overflow: 'visible',
        marginBottom: overflowHeight ? -overflowHeight : 0,
        // ...swipeableViewsProps.slideStyle
      },
      bottomSlide: {
        marginBottom: overflowHeight,

      },
      body: {
        overflow: isOpen ? 'auto' : 'hidden',
        backgroundColor: 'white',
        height: fullScreen ? maxHeight : 'initial',
        maxHeight,
        borderRadius: '16px 16px 0 0',
        // ...this.props.bodyStyle
      },
    },
    overlay: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      height,
      transition: 'opacity 450ms',
      pointerEvents: 'none',
      backgroundColor: 'black',
      opacity: 0,
      ...isOpen && {
        opacity: 0.54,
        pointerEvents: 'auto',
      },
      // ...this.props.overlayStyle
    },
    shadowTip: {
      position: 'fixed',
      height: 60,
      width: '200%',
      bottom: -60,
      left: '-50%',
      boxShadow: 'rgba(0, 0, 0, 0.7) 0px 0px 30px',
      transition: 'transform 450ms',
      transform: isOpen ? 'translateY(50px)' : 'translateY(0)',
    },
  } as SwipeableCSS;

  return (
    <div style={styles.root}>
      <HightUpdater
        height={height}
        onHeightChange={setHeight}
      />

      {overlay && <div style={styles.overlay} onClick={() => onChangeIndex(0)} />}

      <SwipeableViews
        index={index}
        axis="y"
        enableMouseEvents
        onChangeIndex={onChangeIndex}
        style={styles.swiper.root}
        containerStyle={styles.swiper.container}
        slideStyle={styles.swiper.slide}
      >
        <div
          ref={bodyRef}
          style={styles.swiper.body}
          className={`ReactSwipeableBottomSheet--${isOpen ? 'open' : 'closed'}`}
        >
          {/* {this.props.children} */}
          <BottomSheetContent />
        </div>

        <div style={styles.swiper.bottomSlide} />
      </SwipeableViews>

      {shadowTip && !hideShadows && <div style={styles.shadowTip} />}
    </div>
  );
};

export default BottomSheet;
