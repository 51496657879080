import * as React from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine';
import { createElementHook, LeafletContextInterface, useLeafletContext } from '@react-leaflet/core';
import '../../utils/lrm-graphhopper-new';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import reduxStore from '../../redux/init';
import { setGraphHopper } from '../../redux/graphHopper/actions';

type Props = {
  waypoints: L.LatLng[],
  uuid: string,
};

function createRouting(props: Props, context: LeafletContextInterface) {
  const { waypoints } = props;
  const color = '#005598';

  const leafletGraphHopper = L.Routing.control({
    defaultErrorHandler: () => false,
    waypoints,
    router: L.Routing.graphHopper(undefined /* no api key */, {
      serviceUrl: `https://app.prod.taxiapp.ro/api/v1/orders/${props.uuid}/route`,
    }),
    show: false,
    fitSelectedRoutes: false,
    routeWhileDragging: false,
    addWaypoints: false,
    lineOptions: {
      styles: [{ color, opacity: 1, weight: 3 }],
      extendToWaypoints: true,
      missingRouteTolerance: 0,
      missingRouteStyles: [
        { color: 'black', opacity: 0.15, weight: 7 },
        { color: 'white', opacity: 0.6, weight: 4 },
        {
          color, opacity: 0.8, weight: 2, dashArray: '7,12',
        },
      ],
    },
    // @ts-expect-error: No type definition for this property in leaflet
    createMarker: function cm() {
      return null;
    },
  })
    // .on('routingstart', (e) => {
    //   console.log('routingstart', e);
    // })
    .on('routesfound', (e: L.Routing.RoutingResultEvent) => {
      const {
        // waypoints: wp,
        routes,
      } = e;

      // console.log('routesfound', wp, routes);

      reduxStore.dispatch(setGraphHopper({
        routes,
      }));
    })
    // .on('routeselected', (e: L.Routing.RouteSelectedEvent) => {
    //   console.log('routeselected', e);
    //   const r = e.route;
    //   const line = L.Routing.line(r);
    //   const bounds = line.getBounds();
    //   map.fitBounds(bounds, { padding: [20, 20] });
    // })
    .on('routingerror', (e: L.Routing.RoutingErrorEvent) => {
      const { error } = e;

      // console.log('routingerror', error);

      reduxStore.dispatch(setGraphHopper({
        error,
      }));
    });

  return { instance: leafletGraphHopper, context };
}

function updateRouting(instance: L.Routing.Control, props: Props, prevProps: Props) {
  if (!isEqual(prevProps.waypoints, props.waypoints)) {
    instance.setWaypoints(props.waypoints);
  }
}

const useRoutingElement = createElementHook(createRouting, updateRouting);

function Routing(props: Props): null {
  const context = useLeafletContext();
  const elementRef = useRoutingElement(props, context);

  React.useEffect(() => {
    const container = context.map;
    container.addControl(elementRef.current.instance);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      container.removeControl(elementRef.current.instance);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default Routing;
